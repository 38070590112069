@import 'variables';

.container {
    position: fixed;
    top: $header-height;
    left: $switcher-width;
    width: $sidebar-width;
    height: 100vh;
    background-color: $gray-50;
    border-right: 1px solid $gray-100;
    z-index: 11;
    overflow-y: auto;

    ul {
        background-color: transparent !important;
        min-width: auto;
        padding: 5px;
        margin-bottom: $footer-height + $pt-grid-size;

        li a {
            background-color: transparent !important;
            font-weight: bold;
            color: $gray-700;
            padding: 7px 14px;
        }
    }

    ~ div {
        margin-left: $sidebar-width;
        width: calc(100vw - #{$switcher-width} - #{$sidebar-width} - #{$default-padding}) !important;
    }
}
