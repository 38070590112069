@import 'variables';

.group {
    box-shadow: $pt-input-box-shadow !important;

    button {
        box-shadow: none !important;

        &:hover {
            background-color: $gray-50 !important;
        }

        &:global(.bp3-active) {
            background-color: $blue-100 !important;
            color: $blue-500 !important;
            font-weight: bold;
        }

        &:first-child {
            border-right: 0px !important;
        }

        &:last-child {
            border-left: 0px !important;
        }
    }
}
