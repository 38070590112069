@import "variables";

.menu {
    strong {
        color: $pt-intent-primary !important;
    }

    li > a {
        padding: 0 10px;
    }

    li > a > div > div > div > div:first-child {
        align-self: flex-start;
        margin-top: 10px;
    }
}
