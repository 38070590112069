@import "variables";

.bp3-button {
    &[class*="bp3-intent-"] .bp3-button-text {
        font-weight: 700;
        text-shadow: 0px 1px 1px rgba($gray-900, 0.2);
    }

    &:disabled {
        .bp3-button-text {
            text-shadow: none;
        }
    }

    &:hover {
        box-shadow: none;
    }

    // TODO: make this work properly
    &.bp3-minimal {
        .bp3-button-text {
            text-shadow: none;
        }

        &.bp3-intent-success {
            background-color: rgba($pt-intent-success, 0.15);
            &:hover {
                background-color: $pt-intent-success;
                color: $white;
            }
        }
    }

    &:not([class*="bp3-intent-"]) {
        background-color: $gray-100;
    }

    &:not([class*="bp3-intent-"]):hover {
        background-color: $gray-100;
    }
}

.bp3-popover .bp3-popover-content .bp3-input-group {
    margin: 10px;
}

.bp3-menu {
    padding: 8px 0 8px 0 !important;
}

.bp3-menu-item {
    &.bp3-intent-primary.bp3-active {
        background-color: $gray-100;
        color: $blue-500;
    }

    &:hover {
        background-color: $blue-100;
    }

    .bp3-menu-item-label {
        button.bp3-button {
            margin: -8px 0 0 -28px;
            position: absolute;
        }
    }

    .bp3-icon {
        margin-top: 1px !important;
    }
}

.bp3-breadcrumbs a, .bp3-breadcrumbs .bp3-button .bp3-icon {
    font-size: 18px;
    color: $gray-800;
    font-weight: 700;

    &:hover {
        color: $blue-500;
    }
}

$breadcrumb-height: 36px;
.bp3-breadcrumbs {
    height: $breadcrumb-height;

    .bp3-icon {
        margin: -1px 10px 0 0;
        vertical-align: middle !important;
    }

    .bp3-popover-wrapper {
        margin: 0 0 0 10px;
    }

    li {
        align-items: flex-end;
        height: $breadcrumb-height;
        max-height: $breadcrumb-height;

        &:nth-last-child(-n+3) {
            a {
                strong {
                    font-weight:bold !important;
                }
            }
        }

        // &:last-of-type {
        //     a {
        //         strong {
        //             font-weight:600 !important;
        //         }
        //     }
        // }

        &::after {
            width: 5px;
            height: 20px;
            font-size: 16px;
            color: $gray4;
            content: "/";
            background: 0;
            text-align: center;
            line-height: 1;
            margin: 0px $default-padding;
        }

        h4 {
            color: $gray-200;
            font-size: 10px;
            line-height: 12px;
            height: 12px;
            letter-spacing: 1px;
            font-weight: 700;
            margin: 0;
            text-transform: uppercase;
        }

        a {
            max-height: $breadcrumb-height;
            font-weight: normal;
            font-size: 18px !important;

            div > div:last-of-type {
                // background-color: $green-500;
                height: 24px;
                strong {
                    height: 24px;
                }
            }

            div > div:first-of-type {
                // background-color: $blue-500;
                height: 12px;
            }

            &:hover {
                .bp3-icon {
                    color: $gray-800;
                }
            }
        }
    }
}

.bp3-form-group .bp3-form-helper-text {
    line-height: 18px;
}

.bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
    color: $gray-300;
}

.bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
    color: $red1;
}

.bp3-popover.bp3-minimal.bp3-popover {
    background: #FFF;
}

.bp3-tag {
    font-weight: 900;
    background-color: $gray-100;
    color: $gray-700;
}

.bp3-html-table {
    width: 100%;
}

.bp3-form-helper-text {
    span {
        display: inline-block;
        width: 100%;
    }
}

.bp3-menu {
    padding: $default-padding;

    .bp3-menu-item {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: $default-padding;

        .bp3-icon {
            margin-top: 0;
        }
        > div {
            font-weight: 700;
        }

        &:hover {
            .bp3-icon {
                color: $gray-300;
            }
            > div {
                color: $gray-800;
            }
        }

        &.bp3-active:hover {
            .bp3-icon, > div {
                color: $blue-500;
            }
        }

        &.bp3-disabled, &.bp3-disabled:hover {
            .bp3-icon, > div {
                color: $light-gray3;
            }
        }

        &.bp3-intent-danger:hover {
            .bp3-icon, > div {
                color: #FFF;
            }
        }
    }
}

.bp3-menu-header {
    border: 0;
}

.bp3-menu-header .bp3-heading {
    color: $gray-200;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 8px 0 6px 0;
    text-transform: uppercase;
}

.bp3-menu-divider {
    margin: 8px;
}

.bp3-form-group {
    .bp3-multi-select {
        color: $gray-800 !important;
        background-color: $white !important;
        border: $pt-input-border !important;
        box-shadow: $pt-input-box-shadow !important;
        font-weight: 400;
        font-size: 14px !important;
        width: 100%;
        min-height: 40px !important;
    }

    .bp3-popover-target {
        width: 100%;
    }
}


.bp3-input-group {
    input {
        color: $gray-800 !important;
        background-color: $white !important;
        border: $pt-input-border !important;
        box-shadow: $pt-input-box-shadow !important;
        font-weight: 400;
        font-size: 14px !important;

        &[readonly] {
            background-color: $gray-50 !important;
            box-shadow: $pt-input-box-shadow !important;
        }

        &:focus {
            box-shadow: 0px 0px 0px 4px rgba($pt-intent-primary, .1) !important;
            border: 1px solid $pt-intent-primary !important;
        }
    }

    &.bp3-disabled {
        input {
            color: $gray4 !important;
        }
    }

    &.bp3-intent-danger {
        input {
            border: 1px solid $pt-intent-danger !important;
            &:focus {
                box-shadow: 0px 0px 0px 4px rgba($pt-intent-danger, .05) !important;
            }
        }
    }

    &.bp3-intent-warning {
        input {
            border: 1px solid $pt-intent-warning !important;
            &:focus {
                box-shadow: 0px 0px 0px 4px rgba($pt-intent-warning, .1) !important;
            }
        }
    }

    &.bp3-intent-success {
        input {
            border: 1px solid $pt-intent-success !important;
            &:focus {
                box-shadow: 0px 0px 0px 4px rgba($pt-intent-success, .1) !important;
            }
        }
    }
}

.bp3-timepicker-input-row {
    height: 40px !important;
    padding: 0 !important;
    color: $gray-800 !important;
    background-color: $white !important;
    border: $pt-input-border !important;
    box-shadow: $pt-input-box-shadow !important;

    &:focus-within {
        border: 1px solid $pt-intent-primary !important;
    }

    .bp3-timepicker-input {
        width: 38px !important;
        height: 38px !important;
        line-height: 38px !important;
        // font-weight: 400 !important;
        font-size: 14px !important;

        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

.bp3-form-group {
    margin-bottom: 20px;

    .bp3-label {
        font-size: 14px;
        color: $gray-500;
        margin-bottom: 2px !important;
    }
}

.bp3-slider {
    min-width: 100px;
}

.bp3-slider-progress.bp3-intent-primary {
    background-color: $blue-800;
}

.bp3-slider-track {
    background-color: $gray-100;
}

.bp3-slider-label {
    font-size: 14px;
}

.bp3-slider-progress {
    background-color: $gray-100;

    &[class*="intent"] {
        border-radius: 3px;
        overflow: hidden !important;
    }
}

.bp3-slider-handle {
    .bp3-slider-label {
        display: none !important;
    }
}

.bp3-slider.bp3-disabled {
    opacity: 0.8 !important;
    cursor: default !important;
}

.bp3-toast-container.bp3-toast-container-top {
    top: -10px;
}

.bp3-toast-message {
    align-self: center;
}

.bp3-inline.bp3-form-group {
    .bp3-form-content {
        width: 100%;
    }
}

.bp3-dialog {
    background: #FFF;

    .bp3-alert-body {
        align-items: center;
    }
}

.bp3-tag {
    font-size: 12px !important;
}

.bp3-tabs, .bp3-tab-list, .bp3-tab {
    height: 40px;
}

.bp3-tabs {
    border-bottom: 1px solid $gray-100;
}

.bp3-tab {
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    padding: 0 30px;
}

.bp3-icon {
    &[class*="bp3-icon-status"] {
        color: white !important;
    }
}
