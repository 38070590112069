@import 'variables';

.container {
    padding: 2* $default-padding;

    &.borderedTop { border-top: 1px solid $gray-100; }
    &.borderedRight { border-right: 1px solid $gray-100; }
    &.borderedBottom { border-bottom: 1px solid $gray-100; }
    &.borderedLeft { border-left: 1px solid $gray-100; }
}
