@import 'variables';

$width: 280px;
$height: 40px;

.container {
    width: $width;
    height: $height;
}

.inputContainer {
    width: $width !important;
    height: $height !important;

    input {
        height: $height;
        padding-left: $height !important;
    }

    span {
        margin: 12px $default-padding !important;
    }
}
