@import 'variables';

.slider {
    :global(.bp3-slider-axis) {
        position: relative;

        :first-child {
            transform: translate(0%, 20px) !important;
            left: -6px !important;
        }

        :last-child {
            transform: translate(0%, 20px) !important;
            left: auto !important;
            right: -5px !important;
        }

        :global(.bp3-slider-label) {
            color: $gray-500;
        }
    }

    &.fat {
        :global(.bp3-slider-track), :global(.bp3-slider-progress) {
            top: 3px;
            height: 12px !important;
        }

        :global(.bp3-slider-axis) {
            top: 7px;
        }
    }
}

.valueLabel {
    font-size: 14px !important;

    strong {
        font-weight: bold;
    }
}
