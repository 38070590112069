@import 'variables';

.button {
    svg {
        color: $blue-500 !important;
        width: 23px;
        height: 23px;
    }

    &:hover {
        svg {
            color: $blue-700 !important;
        }
    }
}
