@import 'variables';

.slider {
    min-height: 20px;
    margin-bottom: 25px;
    margin-top: -10px;
}

.iconWrapper {
    background-color: $gray-100;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleWrapper {
    margin-bottom: -17px;
}
