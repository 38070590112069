@import './variables';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $footer-height;
    padding: 0 30px;
    color: $gray-500;
    font-size: 10px;
    width: calc(100vw - #{$switcher-width} - #{2 * $default-padding});

    a {
        color: $gray-500 !important;
        font-size: 10px;
        background-color: transparent !important;

        &:hover {
            color: $gray-600 !important;
            svg {
                color: $gray-600 !important;
            }
        }
    }
}
