@import 'variables';

.container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
    padding: 0 0 20px 0;
}

.content {
    padding: 0 0 $default-padding;
    min-height: calc(100vh - #{$header-height} - #{$footer-height} - 20px - 30px);
    display: flex;
    flex-direction: column;
}
