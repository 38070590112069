@import 'variables';

.container {
    strong {
        font-size: 14px;
        font-weight: bold;
    }

    .resource {
        margin: 12px 0 10px 0;

        span, strong {
            font-size: 18px;
            color: $blue-800;
        }
    }
}
