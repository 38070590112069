@import 'variables';

.container {
    width: 320px;
    padding: 5px !important;

    &:empty {
        display: none;
    }
}

.container {
    .item {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: transparent !important;
        border-radius: 0 !important;

        &:hover, &.selected:hover {
            background-color: rgba($blue-400, 0.2) !important;
        }

        &.selected {
            background-color: rgba($blue-400, 0.1) !important;
        }
    }
}
