@import "variables";

.group {
    border: 1px solid $gray-300 !important;
    border-radius: 4px;


    :global(.bp3-button) {
        background-color: $gray-50 !important;

        &:global(.bp3-active) {
            color: $white !important;
            background-color: $blue-500 !important;
        }
    }
}
