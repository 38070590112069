@import 'variables';

.container {
    button.itemButton.interactive {
        cursor: pointer;

        &:hover {
            div, span.externalLink {
                color: $pt-intent-primary !important;
            }
        }
    }

    button.itemButton {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 5px;
        padding-bottom: 5px;
        max-width: 100% !important;

        &:not([class*="interactive"]) {
            pointer-events: none;
            cursor: default !important;
        }

        span:global(.bp3-button-text) {
            color: $gray-500;
            font-weight: normal !important;
            display: inline-flex;
            overflow: hidden;
        }

        .externalLink {
            display: none !important;
        }

        &:hover {
            span.externalLink {
                svg {
                    color: $pt-intent-primary !important;
                }

                display: block !important;
                color: $pt-intent-primary !important;
            }
        }
    }
}
