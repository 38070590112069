@import 'variables';

:global(.bp3-button):global(.bp3-minimal) {
    background-color: transparent !important;
    color: $gray-300 !important;

    &:not([class*="intent"]) {
        :global(.bp3-button-text) {
            color: $gray-500 !important;
        }
    }

    &:hover {
        &:global(.bp3-intent-primary) {
            color: $pt-intent-primary !important;
        }
        &:global(.bp3-intent-danger) {
            color: $pt-intent-danger !important;
        }
        &:global(.bp3-intent-warning) {
            color: $pt-intent-warning !important;
        }
        &:global(.bp3-intent-success) {
            color: $pt-intent-success !important;
        }

        &:not([class*="intent"]) {
            svg {
                color: $gray-500 !important;
            }

            :global(.bp3-button-text) {
                color: $gray-700 !important;
            }
        }
    }

    &:not([class*="intent"]) {
        :global(.bp3-button-text) {
            font-weight: bold !important;
        }
    }
}

.square, .round {
    width: 40px;
    height: 40px;
    overflow: hidden;
    padding: 0 !important;

    &:not(:hover):not(:global(.bp3-intent-primary)):not(:global(.bp3-intent-danger)):not(:global(.bp3-intent-success)) {
        background: transparent;
    }

    &.active {
        box-shadow: 0 0 0 3px rgba($white, .3) !important;
    }
}

.square {
    border-radius: 5px !important;
}

.round {
    border-radius: 20px !important;
}

:global(.bp3-input-action) {
    .square {
        margin: 0 !important;
    }
}

.bordered {
    background-color: $white !important;
    box-shadow: $pt-input-box-shadow !important;
    border: $pt-input-border !important;
    height: 40px;

    &:hover {
        color: $black !important;

        svg {
            color: $gray-700;
        }
    }
}
