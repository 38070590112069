@import 'variables';

.indicator {
    :global(.CircularProgressbar-text) {
        fill: $gray-900 !important;
        font-size: 18px !important;
    }

    :global(.CircularProgressbar-path) {
        stroke-linecap: butt !important;
    }

    &:global(.bp3-intent-success) {
        :global(.CircularProgressbar-path) {
            stroke: $pt-intent-success !important;
        }

        :global(.CircularProgressbar-trail) {
            stroke: rgba($pt-intent-success, 0.15) !important;
        }
    }

    &:global(.bp3-intent-primary) {
        :global(.CircularProgressbar-path) {
            stroke: $blue-500 !important;
        }

        :global(.CircularProgressbar-trail) {
            stroke: rgba($blue-500, 0.15) !important;
        }
    }

    &:global(.bp3-intent-warning) {
        :global(.CircularProgressbar-path) {
            stroke: $pt-intent-warning !important;
        }

        :global(.CircularProgressbar-trail) {
            stroke: rgba($pt-intent-warning, 0.15) !important;
        }
    }

    &.error {
        :global(.CircularProgressbar-text) {
            fill: $pt-intent-danger !important;
        }
    }
}

.container {
    h2 {
        font-size: 14px;
    }
}
