@import 'variables';

.menuItem {
    .externalIcon {
        color: $gray-200 !important;
    }

    &:hover {
        .externalIcon {
            color: $pt-intent-primary !important;
        }
    }

    div:global(.bp3-text-overflow-ellipsis) {
        white-space: normal !important;
    }
}
