@import 'variables';

.list {
    font-family: $pt-font-family-monospace;
    font-size: 12px !important;
    color: $gray-800 !important;
}

.kindTag {
    margin-right: 5px;
    font-weight: 400 !important;
}
