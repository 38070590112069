@import 'variables';

.tag {
    font-weight: bold !important;

    &:global(.bp3-intent-primary) {
        color: $pt-intent-primary !important;
    }

    &:global(.bp3-intent-success) {
        color: $pt-intent-success !important;
    }

    &:global(.bp3-intent-warning) {
        color: $pt-intent-warning !important;
    }

    &:global(.bp3-intent-danger) {
        color: $pt-intent-danger !important;
    }
}
