@import 'variables';

.container {
    max-height: calc(100vh - 375px);
    flex-direction: column;
    border: 1px solid $gray-400;
    border-radius: 5px;
    border: 1px solid $gray-100;
    overflow: hidden;
}

.container {
    table {
        tbody {
            display: block;
            width: 100%;
            overflow-y: auto;
        }

        thead, tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }

        tbody td {
            vertical-align: middle !important;
        }

        tr {
            overflow: hidden;
        }

        td {
            padding: 0 !important;
            box-shadow: none !important;

            :global(.bp3-form-group) {
                margin-bottom: 0 !important;
            }
        }

        th {
            border-bottom: 1px solid $gray-100;
            box-shadow: none !important;
            background-color: transparent !important;

            svg {
                margin-top: 2px;
            }
        }
    }
}

.header {
    display: flex;
    height: 40px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    align-items: center;
    color: $gray-700;
    background-color: $gray-50 !important;
    text-transform: none;
}

.row {
    width: 100% !important;
    border-bottom: 1px solid $gray-100;

    &:last-child {
        border-bottom: 0;
    }
}

.interactiveRow {
    &:hover {
        background-color: $gray-50 !important;
        cursor: pointer;
    }
}

.interactiveHeader {
    th {
        cursor: pointer;
    }
}

.footer {
    border-top: 1px solid $gray-100;
}

.cell {
    padding: 12px 12px !important;
}

:global(.ReactVirtualized__Table__headerColumn) {
    padding-left: 10px !important;
}

:global(.ReactVirtualized__Table__rowColumn) {
    margin-left: 10px !important;
    margin-right: 0 !important;
}

:global(.ReactVirtualized__Table__rowColumn:first-of-type) {
    margin-left: 0 !important;
}
