@import 'variables';

.button {
    width: 100%;
    height: 34px;
    border-radius: 0 !important;
    justify-content: flex-start !important;
    background-color: $gray-50 !important;
    font-family: $pt-font-family-monospace;
    font-size: 12px !important;
    text-align: left !important;
    padding: 7px 30px !important;

    .body {
        max-width: calc(100% - 110px);
        align-self: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .pill {
        align-self: flex-end;
        background-color: $pt-intent-danger;
    }

    &:hover {
        span {
            color: $gray-800 !important;
        }

        :global(.bp3-tag) {
            color: $white !important;
        }
    }

    :global(.bp3-button-text) {
        width: 100% !important;
        color: $gray-400 !important;
    }

    :global(.bp3-tag) {
        color: $white !important;
        background-color: $gray-200 !important;
        padding: 2px 15px;
    }
}
