@import 'variables';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $default-padding;
    font-size: 16px;
    max-width: 100%;
    min-height: 40px;

    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;
    }

    a.wrapper {
        color: $gray-800 !important;
        text-decoration: none !important;

        &:hover {
            color: $black !important;
        }
    }

    &.compact {
        min-height: 40px;
        margin-bottom: 0px;

        .title {
            h2 {
                font-size: 14px;
                font-weight: bold;
                margin-top: 0;
                margin-bottom: 0;
            }

            h4 {
                margin-bottom: 0;
            }
        }
    }

    &.centered {
        justify-content: center;
    }

    &.interactive {
        cursor: pointer;
    }

    &.stretched {
        .wrapper {
            flex-basis: 100%;
        }
    }

    &.padded {
        margin: 0;
        padding: 2 * $default-padding;

        h4 {
            margin: 0 !important;
        }
    }

    &.bordered {
        border-bottom: 1px solid $gray-100;
    }

    .leftItem {
        margin-left: 5px;
    }

    .rightItem {
        margin-left: auto
    }

    .title {
        justify-content: center;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        max-width: 100%;

        h2 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap:nowrap;
            color: $gray-800;
            font-size: 18px;
            margin-bottom: 5px;
            margin-top: 8px;
            line-height: 20px;
        }

        h2 > span {
            margin-left: 5px;

            > span {
              display: flex;
            }
        }

        h4 {
            margin: 0 0 10px 0;
            font-weight: normal;
            line-height: 20px;
            color: $gray-500;
            font-size: 14px;
            max-width: 100%;

            strong {
              font-weight: 400;
              color: $gray-800;
            }
        }
    }

    .icon {
        margin-right: 10px;
    }

    &.small {
        h2 {
            font-size: 14px;
        }
    }

    &.thin {
        h2 {
            font-weight: normal !important;
        }
    }
}
