@import 'variables';

.container {
    height: 28px;
    display: flex;

    > div {
        max-width: 100%;
    }

    .text {
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .action {
        opacity: 0;
    }

    &:hover {
        .action {
            opacity: 1;
        }
    }
}
