@import 'variables';

.container {
    position: fixed !important;
    top: 0;
    left: $switcher-width;
    right: 0;
    width: auto !important;
    height: $header-height !important;
    padding-left: 15px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    box-shadow: none !important;
    border-bottom: 1px solid $gray-100;
}
