.currentImage {
    position: relative;

    button {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
    }

    &:hover {
        button {
            display: flex;
        }
    }
}
