@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:500&display=swap');

$ns: bp3;

$pt-grid-size: 10px;
$pt-border-radius: 4px;
$pt-font-family: "Nunito Sans", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
$pt-font-family-monospace: "Fira Mono", monospace;

$black: #10161A;
$white: #FFFFFF;

$green-100: #E9F7EF;
$green-200: #C0E7D0;
$green-300: #97D8B2;
$green-400: #6FC894;
$green-500: #46B976;
$green-600: #3C9F65;
$green-700: #318153;
$green-800: #276842;
$green-900: #1C4A2F;

$gray-50: #F6F6F7;
$gray-100: #EEEFF1;
$gray-200: #CFD3D8;
$gray-300: #B0B6BF;
$gray-400: #929AA5;
$gray-500: #737D8C;
$gray-600: #636C79;
$gray-700: #505862;
$gray-800: #40464F;
$gray-900: #2E3238;

$blue-100: #E2F0FD;
$blue-200: #ADD4FB;
$blue-300: #78B8F8;
$blue-400: #429CF5;
$blue-500: #0D80F2;
$blue-600: #0B6ED0;
$blue-700: #0959A9;
$blue-800: #074787;
$blue-900: #053361;

$red-100: #FDE2E7;
$red-300: #F8778D;
$red-500: #F20D33;
$red-800: #88071D;

$purple-100: #EDE8F7;
$purple-300: #AC94DB;
$purple-500: #6A40BF;
$purple-800: #3B246B;

$pink-100: #F7E8F7;
$pink-300: #DB94DB;
$pink-500: #BF40BF;
$pink-800: #6B246B;

$teal-100: #E5F7FA;
$teal-300: #86D9EA;
$teal-500: #27BBD9;
$teal-800: #156979;

$orange-100: #FFF2E0;
$orange-300: #FFC470;
$orange-500: #FF9500;
$orange-800: #B34A00;

$yellow-100: #FFFAE0;
$yellow-300: #FFE770;
$yellow-500: #FFD500;
$yellow-800: #E69900;


$pt-intent-primary: $blue-500;
$pt-intent-success: $green-500;
$pt-intent-warning: $orange-500;
$pt-intent-danger: $red-500;

$pt-app-background-color: $white;
$pt-icon-color: $gray-200;

$card-padding: $pt-grid-size * 4;

$pt-outline-color: rgba($blue-300, 0.6);

$pt-divider-black: rgba($black, 0.15);

$pt-border-shadow-opacity: 0;
$pt-drop-shadow-opacity: 0.1;

@function border-shadow($alpha, $color: $black, $size: 1px) {
  @return 0 0 0 $size rgba($color, $alpha);
}

$pt-elevation-shadow-0: 0 0 0 rgba($black, 0);
$pt-elevation-shadow-1: 0 1px 3px rgba($black, $pt-drop-shadow-opacity);
$pt-elevation-shadow-2: 0 2px 6px rgba($black, $pt-drop-shadow-opacity);
$pt-elevation-shadow-3: 0 8px 24px rgba($black, $pt-drop-shadow-opacity);
$pt-elevation-shadow-4: 0 18px 46px 6px rgba($black, $pt-drop-shadow-opacity);

$pt-text-color: $gray-900;
$pt-text-color-muted: $gray-300;
$pt-text-color-disabled: rgba($pt-text-color-muted, 0.5);
$pt-heading-color: $pt-text-color;
$pt-link-color: $blue-400;
// Default text selection color using #7dbcff
$pt-text-selection-color: rgba(125, 188, 255, 0.6);

$pt-icon-color: $pt-text-color-muted;
$pt-icon-color-hover: $pt-text-color;
$pt-icon-color-disabled: $pt-text-color-disabled;
$pt-icon-color-selected: $pt-intent-primary;

$pt-divider-black: rgba($black, 0.15);

$pt-code-text-color: $gray-800;
$pt-code-background-color: rgba($white, 0.7);

$button-border-width: 0;
$button-gradient: none;
$button-intent-gradient: none;

$button-box-shadow: none;
$button-box-shadow-active: none;
$button-intent-box-shadow: none;
$button-intent-box-shadow-active: none;

$button-padding-small: $pt-grid-size/2 $pt-grid-size;
$button-padding: $pt-grid-size $pt-grid-size*2;
$button-padding-large: $pt-grid-size*2 $pt-grid-size*4;

// "intent": (default, hover, active colors)
// $button-intents: (
//   "primary": ($pt-intent-primary, $forest2, $forest3),
//   "success": ($pt-intent-success, $forest2, $forest3),
//   "warning": ($gray-100, $light-gray3, $gray-300),
//   "danger": ($pt-intent-danger, $red4, $red4)
// );

$minimal-button-background-color: none;
$minimal-button-background-color-hover: $gray-50;
$minimal-button-background-color-active: $gray-100;




$pt-font-size: $pt-grid-size * 1.4;
$pt-font-size-large: $pt-grid-size * 1.6;
$pt-font-size-small: $pt-grid-size * 1.2;

$half-grid-size: $pt-grid-size / 2 !default;

$menu-item-border-radius: 0;
$menu-item-line-height-factor: 1.4;
$menu-item-line-height: round($pt-font-size * $menu-item-line-height-factor);
$menu-item-line-height-large: round($pt-font-size-large * $menu-item-line-height-factor);

$pt-icon-size-standard: 16px;
$pt-icon-size-large: 20px;

$pt-button-height: $pt-grid-size * 3;
$pt-button-height-small: $pt-grid-size * 2.4 !default;
$pt-button-height-smaller: $pt-grid-size * 2 !default;
$pt-button-height-large: $pt-grid-size * 4 !default;

$menu-min-width: $pt-grid-size * 18 !default;
$menu-item-padding: ($pt-button-height - $pt-icon-size-standard);
$menu-item-padding-large: ($pt-button-height-large - $pt-icon-size-large) !default;
$menu-item-padding-vertical: ($pt-button-height - $menu-item-line-height) / 2 !default;
$menu-item-padding-vertical-large:
  ($pt-button-height-large - $menu-item-line-height-large) / 2 !default;

$menu-background-color: $white !default;

$minimal-button-divider-width: 1px !default;
$minimal-button-background-color: none !default;
$minimal-button-background-color-hover: rgba($gray4, 0.3) !default;
$minimal-button-background-color-active: rgba($gray-300, 0.3) !default;

$menu-item-color-active: $gray-100;
$menu-item-color-hover: $gray-50;
$pt-divider-black: $gray-100;

$omnibar-height: 60vh !default;
$omnibar-width: $pt-grid-size * 30 !default;
$omnibar-input-height: $pt-grid-size * 4;

$pt-navbar-height: $pt-grid-size * 6;
$navbar-padding: $pt-grid-size * 3;

$header-height: 65px;
$footer-height: 70px;
$sidebar-width: 200px;
$switcher-width: 70px;
$default-padding: 15px;

$pt-icon-color: $gray-200;

$tooltip-background-color: $gray-800;
$tooltip-text-color: $gray-100;

$pt-input-box-shadow: 0 1px 7px 1px rgba($gray-900, .07);
$pt-input-border: 1px solid $gray-200;
