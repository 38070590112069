@import 'variables';

.container {
    width: 100%;
    height: 100%;
    padding: 2 * $default-padding;

    .title {
        font-size: 18px;
        line-height: 30px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
    }

    h3 {
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        color: $gray-500;
        margin-top: 0;
        margin-bottom: 20px;
    }

    h4 {
        font-size: 16px;
        margin-bottom: 0;
    }

    p {
        margin: 5px 0 20px 0;
        color: $gray-400;
    }

    :global(.bp3-non-ideal-state) {
        :global(.bp3-heading) {
            font-weight: normal;
            font-size: 18px;
        }

        color: $gray-400;
    }

    :global(.bp3-menu) {
        padding: 0 !important;
    }

    &:global(.bp3-intent-danger) {
        background-color: $red-100;
    }
}

.icon {
    margin-bottom: 20px;
}

.footer {
    display: flex;
    padding: 5px 10px;
    margin: 20px -20px -20px -20px;
    min-height: 20px;
    background-color: $gray-50 !important;
    color: $gray-400;

    a {
        flex: 1;
        justify-content: start;
        padding: 0 10px 0 10px !important;
        background-color: transparent !important;
        text-align: left !important;
        color: $gray-400 !important;
    }
}

.title {
    display: flex;
    align-items: center;

    :global(.bp3-tag) {
        height: 10px;
        margin-left: 10px;
    }
}
