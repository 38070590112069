@import 'variables';

.trigger {
    width: 24px;
    height: 24px;
    padding: 0 !important;
    margin-top: -5px;

    &:hover {
        background-color: $gray-100 !important;
    }
}

.menuItem {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
