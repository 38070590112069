@import 'variables';

.container {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: $white;
    }

    span:global(.bp3-icon) {
        margin: 0 !important;
    }

    &:global(.bp3-intent-primary) {
        background-color: $pt-intent-primary !important;
    }

    &:global(.bp3-intent-success) {
        background-color: $pt-intent-success !important;
    }

    &:global(.bp3-intent-warning) {
        background-color: $pt-intent-warning !important;
    }

    &:global(.bp3-intent-danger) {
        background-color: $pt-intent-danger !important;
    }

    &.minimal {
        background-color: transparent !important;

        &:global(.bp3-intent-primary) span {
            color: $pt-intent-primary !important;
        }

        &:global(.bp3-intent-success) span {
            color: $pt-intent-success !important;
        }

        &:global(.bp3-intent-warning) span {
            color: $pt-intent-warning !important;
        }

        &:global(.bp3-intent-danger) span {
            color: $pt-intent-danger !important;
        }
    }
}
