@import 'variables';

$step-width: 140px;
$bullet-size: 30px;

.container {
    display: flex;
    position: relative;
}

.bullet {
    &.active {
        h3 {
            background-color: $pt-intent-primary;
        }

        strong {
            border-bottom: 3px solid $pt-intent-primary;
        }
    }

    h3 {
        display: block;
        width: $bullet-size;
        height: $bullet-size;
        border-radius: $bullet-size / 2;
        margin: 0;
        text-align: center;
        line-height: $bullet-size;
        border: 10px white;
        color: $white !important;
        font-size: 14px;
        font-weight: bold;
        background-color: $gray-300;
    }

    span {
        margin: 0;
    }

    strong {
        display: block;
        height: 40px;
        text-align: center;
        font-size: 14px;
        line-height: 40px;
        font-weight: bold;
        text-shadow: none !important;
        color: $gray-800 !important;
        border-bottom: 3px solid $white;
    }

    &:global(.bp3-intent-danger) h3 {
        background-color: $pt-intent-danger !important;
    }

    &:global(.bp3-intent-success) h3 {
        background-color: $pt-intent-success !important;
    }

    &:global(.bp3-intent-primary) h3 {
        background-color: $pt-intent-primary !important;
    }
}

.bars {
    height: 4px;
    top: 13px;
    left: ($step-width / 2) + ($bullet-size / 2) + 5;
    right: ($step-width / 2) + ($bullet-size / 2) + 5;
    position: absolute;
}

.horizontalBar {
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: $gray-100;

    &.success {
        background-color: $pt-intent-success;
    }

    &.danger {
        background-color: $pt-intent-danger;
    }

    &.primary {
        background-color: $pt-intent-primary;
    }
}
