@import 'variables';

.menu {
    :global(.bp3-menu) {
        max-height: 200px;
        max-width: 450px;
        min-width: 350px;
        overflow-y: scroll;
        margin-bottom: 15px;
        padding-bottom: 0;
        padding-top: 5px;
    }
}
