@import 'variables';

.container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $switcher-width;
    background-color: $gray-900;
    padding: $default-padding;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;

    :global(.bp3-button:hover) {
      background-color: transparent !important;
    }
}
