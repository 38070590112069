@import 'variables';

.tooltip {
    h2 {
        color: $gray-100 !important;
    }

    h4 {
        color: $gray-300 !important;
    }
}
