@import 'variables';

.container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-800;

    &.light {
      background-color: $gray-200;
    }

    img {
        width: 100%;
        height: 100%;
    }

    span {
        font-size: 14px !important;
        color: $gray-100 !important;
        font-weight: bold !important;
    }
}
