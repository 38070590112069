@import 'variables';

.tabs {
    :global(.bp3-tab-list) {
        padding-left: 2 * $default-padding;
        margin-left: -2 * $default-padding;
        margin-right: -2 * $default-padding;
        border-bottom: 1px solid $gray-100;
    }

    :global(.bp3-tab) {
        width: 40px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 2px !important;
        padding: 4px 0 0 0;

        border-width: 1px 1px 0 1px;
        border-color: $gray-100;
        border-style: solid;

        border-radius: 4px 4px 0 0 !important;
        overflow:visible;
    }

    :global(.bp3-tab-indicator) {
        background-color: white !important;
        left: 1px !important;
        bottom: -2px !important;
        width: 38px !important;
    }

    .indicator {
        position: absolute;
        top: -1px;
        width: 40px;
        height: 4px;
        border-radius: 4px 4px 0 0 !important;
        background-color: $gray-100 !important;

        &:global(.bp3-intent-danger) {
            background-color: $pt-intent-danger !important;
        }

        &:global(.bp3-intent-success) {
            background-color: $pt-intent-success !important;
        }

        &:global(.bp3-intent-warning) {
            background-color: $pt-intent-warning !important;
        }
    }
}
