@import 'components/variables';

@import 'assets/icons/svg-icon-map';
@import '~@blueprintjs/core/src/blueprint';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '~react-virtualized/styles.css';
@import '~react-circular-progressbar/dist/styles.css';

@import 'components/overrides';

* {
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
    padding: $header-height 0 0 $switcher-width;
    margin: 0;
    background: $pt-app-background-color;
    overflow-y: scroll;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - #{$header-height});
}

form {
    display: flex !important;
    flex-direction: column;
    width: 100%;
}

th {
    color: $gray-700;
    background-color: $gray-50;
    text-transform: none;
    height: 40px;
    vertical-align: middle !important;
    box-shadow: inset 0 -1px 0 0 $gray-100;
}

h2 {
    a {
        text-decoration: none;
        color: $gray-900;

        &:hover {
            text-decoration: none;
            color: $gray-800;
        }
    }
}
