@import 'variables';

.replicaInfo {
    h3 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0 !important;
    }

    h4 {
        font-size: 12px !important;
    }

    &:global(.bp3-intent-primary) {
        h3 {
            color: $pt-intent-primary !important;
        }
    }

    &:global(.bp3-intent-success) {
        h3 {
            color: $pt-intent-success !important;
        }
    }

    &:global(.bp3-intent-warning) {
        h3 {
            color: $pt-intent-warning !important;
        }
    }

    &:global(.bp3-intent-danger) {
        h3 {
            color: $pt-intent-danger !important;
        }
    }
}
