@import 'variables';

.container {
    display: flex;
    flex-direction: column;

    &.centered {
        min-width: 420px;
        align-self: center;
        justify-content: center;

        .buttons {
            align-self: center;
        }

        .header {
            align-items: center;
        }
    }

    &.inline {
        display: flex;
        flex-direction: row !important;
        margin-top: 20px;

        .content {
            flex-direction: row;
            width: 100%;
        }

        .buttons {
            margin: 0 0 20px 20px;
        }
    }

    &.padded {
        .buttons {
            padding: 2 * $default-padding;
            border-bottom: 1px solid $gray-100;
            margin: 0;
        }
    }
}

.header {
    display: flex;
    flex-direction: column;
}

.footer {
    display: flex;
    margin-top: 40px;
}

.content {
    display: flex;
    flex-direction: column;
    position: relative;

    .buttons {
        // margin-top: 20px;

        button:first-child {
            margin-right: 20px;
        }
    }

    &.disabled {
        opacity: 0.5 !important;
    }

    .disabledOverlay {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
}
