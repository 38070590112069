@import "variables";

.slider {
    cursor: default !important;
    margin-top: -7px;

    :global(.bp3-slider-handle) {
        display: none !important;
    }

    :global(.bp3-slider-label) {
        transform: translate(0, 20px) !important;
        color: $gray-500;
    }
}
